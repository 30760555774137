import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 51;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>相変わらず友達づくりって難しい</li>
        <li>釣りミートアップに参加</li>
        <li>ビーチクリーンアップ</li>
        <li>
          <a
            href="https://nych2o.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            NYCH2O
          </a>
        </li>

        <li>水のことを考える</li>
        <li>都市に住んでいると自然のことを考えにくい</li>
        <li>自分は一番になりたくない感覚</li>
        <li>環境への意識の高まり</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
